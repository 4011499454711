export const LOGO_SYMBOL = 'logo-symbol'
export const LOGO_DOUBLE_LINE = 'logo-double-line'
export const LOGO_DOUBLE_LINE_BIG = 'logo-double-line-big'
export const LOGO_ONE_LINE = 'logo-one-line'
export const LOGO_QUSTION = 'logo-question'
export const LOGO_SEPHORA = 'logo-sephora'
export const LOGO_SEPHORA_DOUBLE_LINE = 'logo-sephora-double-line'
export const LOGO_SKIN_GENOME_QUIZ = 'skin-genome-quiz'

export const VIEW_BOX_BY_LOGO = {
  [LOGO_SYMBOL]: '0 0 93 107',
  [LOGO_DOUBLE_LINE]: '0 0 666 363',
  [LOGO_DOUBLE_LINE_BIG]: '0 0 666 363',
  [LOGO_ONE_LINE]: '0 0 822 113',
  [LOGO_QUSTION]: '0 0 180 25',
  [LOGO_SEPHORA]: '0 0 340 25',
  [LOGO_SEPHORA_DOUBLE_LINE]: '0 0 143 128',
  [LOGO_SKIN_GENOME_QUIZ]: '0 0 143 20'
}
export const SVG_NAME_BY_LOGO = {
  [LOGO_SYMBOL]: LOGO_SYMBOL,
  [LOGO_DOUBLE_LINE]: LOGO_DOUBLE_LINE,
  [LOGO_DOUBLE_LINE_BIG]: LOGO_DOUBLE_LINE,
  [LOGO_ONE_LINE]: LOGO_ONE_LINE,
  [LOGO_QUSTION]: LOGO_QUSTION,
  [LOGO_SEPHORA]: LOGO_SEPHORA,
  [LOGO_SEPHORA_DOUBLE_LINE]: LOGO_SEPHORA_DOUBLE_LINE,
  [LOGO_SKIN_GENOME_QUIZ]: LOGO_SKIN_GENOME_QUIZ
}
