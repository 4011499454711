export const QUESTIONNAIRE_ID_SKIN_PASSWORDLESS =
  process.env.ENV_QUESTIONNAIRE_ID_SKIN || '65413defb9dcec058d971c38'
export const QUESTIONNAIRE_ID_EYE_CREAM_PASSWORDLESS =
  process.env.ENV_QUESTIONNAIRE_ID_EYE_CREAM || '64ac784b6e0d31411c4e613d'
export const QUESTIONNAIRE_ID_SERUM =
  process.env.ENV_QUESTIONNAIRE_ID_SERUM || '64d27ec50b96607becb07c5d'
export const QUESTIONNAIRE_KEY_NAME = '5da5128371447f00158cf098' // name id in questions/answers
export const QUESTIONNAIRE_KEY_ZIPCODE = '5b459e91dc0957fe3429d9e6' // name id in questions/answers
export const QUESTIONNAIRE_KEY_PHONE = '5e5ef18a9630d258f60c6494' // name id in questions/answers
export const QUESTIONNAIRE_KEY_EMAIL = '64ad747dbe242f308af8667d' // name id in questions/answers
export const QUESTIONNAIRE_KEY_PASSWORD = '5da5f9d7dc8d26001511c958' // name id in questions/answers

export const SKIN_QUESTIONNAIRE_URI = '/quiz'
export const SKIN_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/start'
export const SKIN_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/concern'
export const EYE_QUESTIONNAIRE_URI = '/quiz/eye'
export const EYE_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/eye/start'
export const EYE_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/eye/concern'
export const SERUM_QUESTIONNAIRE_URI = '/quiz/serum'
export const SERUM_QUESTIONNAIRE_OPENING_PAGE_URI = '/quiz/serum/start'
export const SERUM_QUESTIONNAIRE_FIRST_QUESTION_URI = '/quiz/serum/concern'

export const QUESTIONNAIRE_URIS = [
  SKIN_QUESTIONNAIRE_URI,
  SKIN_QUESTIONNAIRE_FIRST_QUESTION_URI,
  EYE_QUESTIONNAIRE_URI,
  EYE_QUESTIONNAIRE_FIRST_QUESTION_URI,
  SERUM_QUESTIONNAIRE_URI,
  SERUM_QUESTIONNAIRE_FIRST_QUESTION_URI
]

export const TEMPLATE_OF_QUESTIONNAIRE_INITIAL_REDUX_STATE = {
  answers: { version: 0, id: QUESTIONNAIRE_ID_SKIN_PASSWORDLESS, variant: 'A' },
  ingredients: [],
  completed: false,
  // pending: null,
  questions: [],
  questions_b: [],
  version_a: {},
  version_b: {},
  invalid: null,

  zip_qid: null,
  congrats_page: ''
}

export const CONCERN_QUESTION = {
  _id: '5ee87a1fa0f02600153bbc55',
  question: {
    items: [
      {
        text: 'Sensitivity',
        value: 0
      },
      {
        text: 'Redness',
        value: 1
      },
      {
        text: 'Fine lines or wrinkles',
        value: 2
      },
      {
        text: 'Loss of firmness or elasticity',
        value: 3
      },
      {
        text: 'Hyperpigmentation',
        value: 4
      },
      {
        text: 'Acne',
        value: 5
      },
      {
        text: 'Dryness',
        value: 6
      },
      {
        text: 'Other',
        value: 7
      }
    ],
    _id: '5bd0f2a48e113000132de32d',
    heading: 'What are your main skin concerns?',
    type: 'SelectMultiple',
    group: 'skin Concerns',
    name: 'concern',
    validityCheck: false,
    transition: {
      title: true,
      status: "3 minutes to your best skincare, let's get started!"
    },
    transitionB: {
      title: true,
      status: "3 minutes to your best skincare, let's go!"
    },
    createdAt: '2018-11-02T20:52:37.362Z',
    updatedAt: '2019-11-06T23:24:02.956Z',
    __v: 0,
    subHeading: null,
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg'
  }
}

export const CONCERN_QUESTION_EYE = {
  _id: '620e9a46f6672000168ce2ed',
  question: {
    _id: '620be33b078ac800166fe6be',
    items: [
      {
        text: 'Sensitivity or Redness',
        value: 0
      },
      {
        text: 'Fine Lines and Wrinkles',
        value: 1
      },
      {
        text: 'Dark Circles',
        value: 2
      },
      {
        text: 'Drooping or Loss of Elasticity',
        value: 3
      },
      {
        text: 'Uneven Skin Tone',
        value: 4
      },
      {
        text: 'Puffiness or Eye Bags',
        value: 5
      },
      {
        text: 'Dryness',
        value: 6
      }
    ],
    heading: 'What are your top eye-area skin concerns?',
    type: 'SelectMultiple',
    group: 'Skin Concerns',
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg',
    name: 'concern',
    validityCheck: false,
    transitionB: {
      title: true,
      status: "3 minutes to your Personalized Eye Cream Duo. Let's go!"
    },
    createdAt: '2022-02-15T17:30:35.923+0000',
    updatedAt: '2022-02-17T03:19:46.341+0000',
    __v: 0
  }
}

export const CONCERN_QUESTION_SERUM = {
  _id: '5ee87a1fa0f02600153bbc55',
  question: {
    items: [
      {
        text: 'Sensitivity',
        value: 0
      },
      {
        text: 'Redness',
        value: 1
      },
      {
        text: 'Fine lines or wrinkles',
        value: 2
      },
      {
        text: 'Loss of firmness or elasticity',
        value: 3
      },
      {
        text: 'Hyperpigmentation',
        value: 4
      },
      {
        text: 'Acne',
        value: 5
      },
      {
        text: 'Dryness',
        value: 6
      },
      {
        text: 'Other',
        value: 7
      }
    ],
    _id: '5bd0f2a48e113000132de32d',
    heading: 'What are your main skin concerns?',
    type: 'SelectMultiple',
    group: 'skin Concerns',
    name: 'concern',
    validityCheck: false,
    transition: {
      title: true,
      status: "3 minutes to your best skincare, let's get started!"
    },
    transitionB: {
      title: true,
      status: "3 minutes to your best skincare, let's go!"
    },
    createdAt: '2018-11-02T20:52:37.362Z',
    updatedAt: '2019-11-06T23:24:02.956Z',
    __v: 0,
    subHeading: null,
    groupImage: 'https://dl7bo1dy930sf.cloudfront.net/img/icons/icon-kit-skin-concerns.svg'
  }
}
