const SENT_TEMPORARY_PASSWORD =
  "We've sent you an email with a temporary password. Please use it to login and reset your password."

const SENT_RESET_EMAIL = "We've sent you an email to reset password."

const ERROR_FORCE_CHANGE_PASSWORD = "Sorry, you're not able to change your password at this moment."

const SECOND_PLUS_SUBSCRIPTION =
  'It may take a couple of minutes for the new subscription to show up in your account.'

export {
  SENT_TEMPORARY_PASSWORD,
  SENT_RESET_EMAIL,
  ERROR_FORCE_CHANGE_PASSWORD,
  SECOND_PLUS_SUBSCRIPTION
}
