export const TYPE_SYSTEM = 'system'
export const TYPE_TAB = 'tab'
export const TYPE_TAG = 'tag'
export const TYPE_KIT = 'kit'
export const TYPE_DASHBOARD = 'dashboard'
export const TYPE_SOCIAL = 'social'

export const VIEW_BOX_BY_ICON_TYPE = {
  [TYPE_SYSTEM]: '0 0 18 18',
  [TYPE_TAB]: '0 0 24 24'
}
export const ICON_TYPES_IN_CDN = [TYPE_KIT, TYPE_SOCIAL, TYPE_TAG]
export const ICON_SIZES = ['small', 'medium', 'large']

export const DEFAULT_FIXED_SIZE_BY_TYPE = {
  [TYPE_SYSTEM]: 'small',
  [TYPE_TAB]: 'medium',
  [TYPE_DASHBOARD]: 'medium',
  [TYPE_SOCIAL]: 'medium',
  [TYPE_KIT]: 'large'
}
