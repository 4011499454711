const validations = {
  empty: 'Please select your answer',
  selectLimit: maxFields => `Please select ${maxFields} answers`,
  zip: 'Please enter a valid Postal code',
  email: 'Invalid email',
  date: 'Invalid year',
  name: 'Please enter your name',
  phone: 'Please enter a valid 10 digit phone number'
}

export default validations
