// Funnel states
import { APP_EDITION_SEPHORA } from './constants'

export const FUNNEL_NOT_STARTED = 0
export const FUNNEL_QUIZ_COMPLETED = 1
export const FUNNEL_SUBSCRIPTION_INACTIVE = 2
export const FUNNEL_SUBSCRIPTION_ACTIVE = 3
export const ALL_FUNNELS = [
  FUNNEL_NOT_STARTED,
  FUNNEL_QUIZ_COMPLETED,
  FUNNEL_SUBSCRIPTION_INACTIVE,
  FUNNEL_SUBSCRIPTION_ACTIVE
]

// Products keywords to track
export const KEYWORD_EYE = 'eye'
export const KEYWORD_SKIN = 'skin'
export const KEYWORD_SERUM = 'serum'
/* TO ADD A NEW QUIZ ADD A NEW KEYWORD TO REFERENCE IT FROM questionnaire-configurator.js */

/* add the new keyword in COMBO_PRODUCTS__KEYWORDS only if the product is a "combo" (it means, the product is not part of another product) */
export const COMBO_PRODUCTS__KEYWORDS = [KEYWORD_SKIN, KEYWORD_EYE, KEYWORD_SERUM]

// Landing pages
export const LANDING_PAGES_FOR_SKIN = [
  'personalized-concerns',
  'science',
  'mulberry',
  'offer',
  'trial',
  'skimmers',
  'digitalderm',
  'all-about-you',
  'personalized-subscription',
  'personalized-face-cleanser',
  'personalized-day-moisturizer',
  'personalized-night-cream',
  'skin-type',
  'blog',
  'skin-concern',
  'sensitive-skin-skincare-kit'
]

export const PAGES_FOR_PRODUCT_KEYWORD_BY_APP_EDITION = {
  [APP_EDITION_SEPHORA]: {
    [KEYWORD_SKIN]: ['/']
  }
}

export const LANDING_PAGES_FOR_EYE = []
export const LANDING_PAGES_NEUTRAL = ['reviews', 'why-proven', 'clinical-proof', 'ingredients']
